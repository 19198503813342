export default function () {
  return [
    {
      key: 'facebook',
      title: 'Facebook',
      icon: 'bi:facebook',
      url: 'https://www.facebook.com/tauschwohnung',
    },
    {
      key: 'instagram',
      title: 'Instagram',
      icon: 'bi:instagram',
      url: 'https://www.instagram.com/tauschwohnung',
    },
    {
      key: 'youtube',
      icon: 'bi:youtube',
      title: 'Youtube',
      url: 'https://www.youtube.com/@tauschwohnung',
    },
    {
      key: 'pinterest',
      icon: 'bi:pinterest',
      title: 'Pinterest',
      url: 'https://www.pinterest.de/tauschwohnung/',
    },
    {
      key: 'tiktok',
      icon: 'bi:tiktok',
      title: 'TikTok',
      url: 'https://www.tiktok.com/@tauschwohnung',
    },
    {
      key: 'linkedin',
      icon: 'bi:linkedin',
      title: 'LinkedIn',
      url: 'https://de.linkedin.com/company/tauschwohnung',
    },
  ];
}
